.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 1rem;
    box-shadow: 0px 0px 10px 0px #ccc;
}

.login-input{
    margin: 1rem 0;
    padding: 0.5rem;
    width: 100%;
    border: none;
    border-bottom: 2px solid #ddd;
    font-size: 1.2rem;
}

input:focus {
    border-bottom: 2px solid #000;
    outline: none;
}

.login-button {
    margin-top: 1.5rem;
    padding: 0.75rem 1.5rem;
    background-color: #4CAF50;
    color: white;
    font-size: 1.2rem;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-button:hover {
    background-color: #3e8e41;
}

p {
    color: white;
    font-size: 1.3rem;
    margin-top: .6rem; 
  }

  .signupmessage{
    text-align: center;
  }