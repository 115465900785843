.App {
  display:flex;
  background-color: #282c34;
  color:white;
  position:absolute;
  top:0;bottom:0;right:0;left:0;
}

.sidemenu {
  width:260px;
  padding:10px;
  background-color:#202123;
}
/* under 640px do this */
@media (max-width: 640px) {
  .sidemenu {
    display:none;
  }
}

.side-menu-button {
  padding:12px;
  border:1px solid white;
  border-radius:5px;
  text-align: left;
  transition:ease 0.25s all;
  cursor:pointer;
}
.side-menu-button:hover {
  background-color:rgba(255,255,255,0.1);
}
.side-menu-button span {
  padding-left:6px;
  padding-right:12px;
}

.chatbox {
  flex:1;
  background-color:#343541;
  position:relative;
  overflow-y:scroll;
  padding-bottom:95px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.chat-input-holder {
  padding:24px;
  position:absolute;
  bottom:0;
  left:0;right:0;
}
.chat-input-textarea {
  background-color:#40414f;
  width:90%;
  padding:12px;
  border-radius:5px;
  color:white;
  font-size:1.25em;
  border:none;
  outline:none;
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.25);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.chat-log {
  position: absolute;
  top:0;right:0;bottom:89px;left:0;
  overflow-y:scroll;
  text-align:left;
}

.chat-message.chatgpt {
  background-color:#444654;
}
.chat-message-center {
  max-width:640px;
  margin-left:auto;
  margin-right:auto;
  display:flex;
  padding:12px;
  padding-left: 24px;
  padding-right: 24px;
  
}
.avatar {
  background:white;
  border-radius:50%;
  width: 40px;
  height: 40px;
  display:flex;
  align-items:center;
  justify-content:center;
  padding:5px;
  font-size:14px;
  color:#444654;
}
.avatar.chatgpt {
  background:#0da37f;
  border-radius:50%;
  min-width: 40px;
  min-height: 40px;
  padding:5px;
  color:white;
}
.message {
  padding-left: 40px;
  padding-right: 40px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.select-models {
  border: 1px solid white;
  padding:12px;
  border-radius:5px;
  color:white;
  background:transparent;
  outline:none;
  cursor:pointer;
  max-width:100%;
  min-width:100%;
}
.select-models option {
  background:black;
  color:white;
}

.button-picker {
  border:none;
  padding:6px 12px ;
  border-radius:5px;
  color:white;
  background:#444654;
  margin-top:6px;
  cursor: pointer;
}
.button-picker:hover {
  background:#626473;
}

.info {
  opacity:0.66;
  font-size:0.8em;
  margin-top:6px;
}

.side-label {
  font-size:0.9em;
  margin-top:1em;
  margin-bottom:0.5em;
  padding-bottom:0em;
  display:block;
}

.form {
  display:flex;
  gap:6px;
}

.submit {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  background:#0da37f;
  display:flex;
  align-items:center;
  justify-content:center;
  padding:5px 12px;
  font-size:14px;
  color:white;
  border-radius:5px;
  cursor: pointer;
}
.submit:hover {
  background:#066d55;
}